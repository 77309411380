import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

function EmailSignup() {
  const [email, setEmail] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    const subscribed = Cookies.get("subscribed");
    if (subscribed) {
      setIsSubscribed(true);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://api.lotteryangels.com/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setIsSubscribed(true);
        Cookies.set("subscribed", "true", { expires: 90 });
      } else {
        const errorText = await response.text();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (isSubscribed) {
    return null;
  }

  return (
    <div className="bg-[#F5E5C9] shadow sm:rounded-lg mt-8">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-[#030000]">
          Sign up for lottery updates
        </h3>
        <div className="mt-2 max-w-xl text-sm text-[#030000]">
          <p>
            Get the latest lottery predictions and tips delivered to your inbox.
          </p>
        </div>
        <form className="mt-5 sm:flex sm:items-center" onSubmit={handleSubmit}>
          <div className="w-full sm:max-w-xs">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="you@example.com"
              className="block w-full rounded-md border-0 py-1.5 text-[#030000] shadow-sm ring-1 ring-inset ring-[#DB3E00] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#DB3E00] sm:text-sm sm:leading-6"
            />
          </div>
          <button
            type="submit"
            className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-[#DB3E00] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#c23700] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#DB3E00] sm:ml-3 sm:mt-0 sm:w-auto transition duration-300 ease-in-out"
          >
            Subscribe
          </button>
        </form>
      </div>
    </div>
  );
}

export default EmailSignup;
