import React from "react";
import Layout from "../components/Layout";
import EmailSignup from "../components/EmailSignup";
import States from "../components/States";
import { Helmet } from "react-helmet";

function DailyNumbers() {
  const todaysNumbers = [1, 2, 3, 4, 5]; // Replace with actual logic to generate numbers

  return (
    <>
      <Helmet>
        <title>Today's Daily Lottery Numbers - Lottery Angels</title>
        <meta
          name="description"
          content="View today's lottery number predictions for all states."
        />
      </Helmet>
      <Layout>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-bold mb-6">Today's Daily Numbers</h1>
          <div className="bg-white p-6 rounded-lg shadow-md mb-8">
            <p className="text-2xl font-bold mb-4">
              Select your state below and get today's daily lottery number
              predictions for the Pick 3 and Pick 4 lotteries.
            </p>
          </div>
          <States />
          <EmailSignup />
        </div>
      </Layout>
    </>
  );
}

export default DailyNumbers;
