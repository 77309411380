import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Layout from "../components/Layout";
import EmailSignup from "../components/EmailSignup";
import States from "../components/States";
import { Helmet } from "react-helmet";

function StateDailyNumbers() {
  const { stateName } = useParams();
  const [stateData, setStateData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStateData = async () => {
      try {
        const response = await fetch(
          `https://api.lotteryangels.com/daily-numbers/${stateName}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch state data");
        }
        const data = await response.json();
        setStateData(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchStateData();
  }, [stateName]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <Helmet>
        <title>{stateData.state} Daily Lottery Numbers - Lottery Angels</title>
        <meta
          name="description"
          content="View today's lottery number predictions for the {stateData.state} state."
        />
      </Helmet>
      <Layout>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-bold mb-6">
            {stateData.state} Daily Lottery Numbers
          </h1>
          <p className="text-xl mb-4">
            Here are the daily lottery number predictions for {stateData.state}{" "}
            for{" "}
            {new Date(stateData.draw_date + "T00:00:00").toLocaleDateString(
              "en-US",
              {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            )}
            . Check back tomorrow for the next set of numbers or use the link
            below to see tomorrow's numbers now.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-bold mb-4">
                Today's Pick 3 Lottery Number Predictions
              </h2>
              <div className="flex flex-wrap gap-4">
                {stateData.pick_3.map((number, index) => (
                  <span
                    key={index}
                    className="bg-yellow-200 text-xl font-bold p-3 rounded-full"
                  >
                    {number}
                  </span>
                ))}
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-bold mb-4">
                Today's Pick 4 Lottery Number Predictions
              </h2>
              <div className="flex flex-wrap gap-4">
                {stateData.pick_4.map((number, index) => (
                  <span
                    key={index}
                    className="bg-green-200 text-xl font-bold p-3 rounded-full"
                  >
                    {number}
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md w-full">
            <h2 className="text-2xl font-bold mb-4">
              Tomorrows Daily Lottery Numbers
            </h2>
            <div className="flex flex-wrap gap-4">
              <Link
                to={`/daily-numbers/${stateName}/tomorrow`}
                className="text-blue-500 hover:underline mb-8 block"
              >
                {stateData.state} Pick 3 and Pick 4 Lottery Numbers For Tomorrow
              </Link>
            </div>
          </div>

          <EmailSignup />
          <div className="bg-white p-6 rounded-lg shadow-md mb-8">
            <p className="text-2xl font-bold mb-4">
              Select another state to see more daily lottery number predictions.
            </p>
          </div>
          <States />
        </div>
      </Layout>
    </>
  );
}

export default StateDailyNumbers;
