import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Layout from "../components/Layout";
import EmailSignup from "../components/EmailSignup";
import Birthstones from "../components/Birthstones";
import { Helmet } from "react-helmet";

function BirthstoneDailyNumbersTomorrow() {
  const { birthstone } = useParams();
  const [birthstoneData, setBirthstoneData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBirthstoneData = async () => {
      try {
        const response = await fetch(
          `https://api.lotteryangels.com/birthstone-numbers/${birthstone}/tomorrow`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch birthstone data");
        }
        const data = await response.json();
        setBirthstoneData(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchBirthstoneData();
  }, [birthstone]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Capitalize the first letter of the birthstone name
  const capitalizedBirthstone =
    birthstone.charAt(0).toUpperCase() + birthstone.slice(1);

  return (
    <>
      <Helmet>
        <title>
          {capitalizedBirthstone} Daily Lottery Numbers for Tomorrow - Lottery
          Angels
        </title>
        <meta
          name="description"
          content="View tomorrow's lottery number predictions for the {capitalizedBirthstone} birthstone."
        />
      </Helmet>
      <Layout>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-bold mb-6">
            {capitalizedBirthstone} Daily Lottery Numbers for Tomorrow
          </h1>
          <p className="text-xl mb-4">
            Here are the daily lottery number predictions for{" "}
            {capitalizedBirthstone} for{" "}
            {new Date(
              birthstoneData.draw_date + "T00:00:00"
            ).toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
            . These are tomorrow's predicted numbers. Check back later for
            updated predictions.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-bold mb-4">
                Tomorrow's Pick 3 Lottery Number Predictions
              </h2>
              <div className="flex flex-wrap gap-4">
                {birthstoneData.pick_3.map((number, index) => (
                  <span
                    key={index}
                    className="bg-yellow-200 text-xl font-bold p-3 rounded-full"
                  >
                    {number}
                  </span>
                ))}
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-bold mb-4">
                Tomorrow's Pick 4 Lottery Number Predictions
              </h2>
              <div className="flex flex-wrap gap-4">
                {birthstoneData.pick_4.map((number, index) => (
                  <span
                    key={index}
                    className="bg-green-200 text-xl font-bold p-3 rounded-full"
                  >
                    {number}
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md w-full">
            <h2 className="text-2xl font-bold mb-4">
              Today's Daily Lottery Numbers
            </h2>
            <div className="flex flex-wrap gap-4">
              <Link
                to={`/birthstone-numbers/${birthstone}`}
                className="text-blue-500 hover:underline block"
              >
                {capitalizedBirthstone} Pick 3 and Pick 4 Lottery Numbers For
                Today
              </Link>
            </div>
          </div>

          <EmailSignup />

          <div className="bg-white p-6 rounded-lg shadow-md mb-8">
            <p className="text-2xl font-bold mb-4">
              Select another birthstone below to see more birthstone lottery
              numbers for today.
            </p>
          </div>

          <Birthstones />
        </div>
      </Layout>
    </>
  );
}

export default BirthstoneDailyNumbersTomorrow;
