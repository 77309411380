import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

function AboutUs() {
  return (
    <>
      <Helmet>
        <title>About Us - Lottery Angels</title>
        <meta
          name="description"
          content="Learn about the story behind Lottery Angels and how we help people win the lottery."
        />
      </Helmet>
      <Layout>
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                About Us
              </h2>
              <div className="mt-6 text-lg leading-8 text-gray-600">
                <p className="mb-4">
                  Welcome to Lottery Angels, your ultimate destination for
                  everything related to playing the lottery! My name is Grace,
                  and I am a passionate lottery enthusiast who has been
                  participating in this exciting game of chance for over 25
                  years. During this time, I've managed to win tens of thousands
                  of dollars across multiple state lotteries, focusing primarily
                  on Pick 3 and Pick 4 daily lucky lottery numbers. Now, I want
                  to share my unique approach with you.
                </p>
                <p className="mb-4">
                  My winning secret lies in my firm belief that the universe,
                  through its divine messengers – the angels, is constantly
                  guiding us towards success. I believe that there are winning
                  lottery numbers all around us, provided for us by angels who
                  want to see us prosper. This belief has led me to develop a
                  deep understanding and connection with angel numbers, which
                  has in turn, helped me secure multiple lottery wins in Pick 3
                  and Pick 4 games.
                </p>
                <p>
                  With Lottery Angels, I've made it my mission to help others
                  unlock the hidden potential of angel numbers and find their
                  own winning lottery combinations, specifically for Pick 3 and
                  Pick 4 daily draws. Join me on this thrilling journey, and
                  together, let's embrace the guidance of the angels to achieve
                  our wildest dreams!
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default AboutUs;
