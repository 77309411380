import React from "react";

const navigation = {
  main: [
    { name: "About", href: "/about" },
    { name: "Privacy Policy", href: "/privacy-policy" },
    { name: "Contact Us", href: "mailto:info@lotteryangels.com" },
  ],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/lotteryangels/",
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/lotteryangels",
    },
    {
      name: "Twitter",
      href: "https://www.twitter.com/lotteryangels",
    },
    // ... (social media icons and links as provided)
  ],
};

function Footer() {
  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
        <nav
          aria-label="Footer"
          className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12"
        >
          {navigation.main.map((item) => (
            <div key={item.name} className="pb-6">
              <a
                href={item.href}
                className="text-sm leading-6 text-gray-600 hover:text-gray-900"
              >
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <div className="mt-10 flex justify-center space-x-10">
          {navigation.social.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-gray-400 hover:text-gray-500"
            >
              {item.name}
            </a>
          ))}
        </div>
        <p className="mt-10 text-center text-xs leading-5 text-gray-500">
          &copy; 2024 Lottery Angels. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
