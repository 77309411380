import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Lottery Angels</title>
        <meta
          name="description"
          content="Learn about the privacy policy of Lottery Angels."
        />
      </Helmet>
      <Layout>
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Privacy Policy
              </h2>
              <div className="mt-6 text-lg leading-8 text-gray-600">
                <p className="mb-4">
                  At Lottery Angels, we are committed to protecting your
                  privacy. This Privacy Policy explains how we collect, use,
                  disclose, and safeguard your information when you visit our
                  website or use our services.
                </p>
                <h3 className="text-xl font-semibold mt-6 mb-2">
                  Information We Collect
                </h3>
                <p className="mb-4">
                  We may collect personal information that you provide directly
                  to us, such as your name, email address, and any other
                  information you choose to provide.
                </p>
                <h3 className="text-xl font-semibold mt-6 mb-2">
                  How We Use Your Information
                </h3>
                <p className="mb-4">
                  We use the information we collect to provide, maintain, and
                  improve our services, to communicate with you, and to comply
                  with legal obligations.
                </p>
                <h3 className="text-xl font-semibold mt-6 mb-2">
                  Sharing of Your Information
                </h3>
                <p className="mb-4">
                  We do not sell, trade, or otherwise transfer your personally
                  identifiable information to outside parties. This does not
                  include trusted third parties who assist us in operating our
                  website or servicing you.
                </p>
                <h3 className="text-xl font-semibold mt-6 mb-2">Security</h3>
                <p className="mb-4">
                  We implement a variety of security measures to maintain the
                  safety of your personal information.
                </p>
                <h3 className="text-xl font-semibold mt-6 mb-2">
                  Changes to This Privacy Policy
                </h3>
                <p className="mb-4">
                  We may update our Privacy Policy from time to time. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page.
                </p>
                <h3 className="text-xl font-semibold mt-6 mb-2">Contact Us</h3>
                <p>
                  If you have any questions about this Privacy Policy, please
                  contact us at [Your Contact Information].
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default PrivacyPolicy;
