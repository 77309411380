import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage";
import DailyNumbers from "./pages/DailyNumbers";
import BirthstoneNumbers from "./pages/BirthstoneNumbers";
import LuckyNumbers from "./pages/LuckyNumbers";
import StateDailyNumbers from "./pages/StateDailyNumbers";
import StateDailyNumbersTomorrow from "./pages/StateDailyNumbersTomorrow";
import BirthstoneDailyNumbers from "./pages/BirthstoneDailyNumbers";
import BirthstoneDailyNumbersTomorrow from "./pages/BirthstoneDailyNumbersTomorrow";
import AboutUs from "./pages/AboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NotFound from "./pages/NotFound";
import ReactGA from "react-ga4";

ReactGA.initialize("G-SZN669W0PV");

ReactGA.send("pageview");

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="/category/lucky-lottery-numbers"
          element={<Navigate to="/daily-numbers" replace />}
        />
        <Route path="/daily-numbers" element={<DailyNumbers />} />
        <Route
          path="/daily-numbers/:stateName"
          element={<StateDailyNumbers />}
        />
        <Route path="/birthstone-numbers" element={<BirthstoneNumbers />} />
        <Route path="/lucky-numbers" element={<LuckyNumbers />} />
        <Route
          path="/daily-numbers/:stateName/tomorrow"
          element={<StateDailyNumbersTomorrow />}
        />
        <Route
          path="/birthstone-numbers/:birthstone"
          element={<BirthstoneDailyNumbers />}
        />
        <Route
          path="/birthstone-numbers/:birthstone/tomorrow"
          element={<BirthstoneDailyNumbersTomorrow />}
        />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
}

export default App;
