import React from "react";
import { Link } from "react-router-dom";
import EmailSignup from "../components/EmailSignup";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import Layout from "../components/Layout"; // Add this import
import { Helmet } from "react-helmet";

const benefits = [
  "Daily lucky numbers",
  "Horoscope numbers",
  "Power Numbers",
  "Love Angel Numbers",
  "Fortune cookie numbers",
  "Day of week numbers",
];

function HomePage() {
  return (
    <>
      <Helmet>
        <title>Lottery Angels - Home</title>
        <meta
          name="description"
          content="Lottery Angels is your ultimate destination for lottery number predictions, personalized lucky numbers, and state-specific lottery information."
        />
      </Helmet>
      <Layout>
        {" "}
        {/* Wrap the entire content with the Layout component */}
        <div className="container mx-auto px-4 py-8">
          <div className="bg-[#F5E5C9] py-12 sm:py-16 rounded-lg mb-8">
            <div className="relative isolate">
              <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-[#F5E5C9] px-6 py-16 ring-1 ring-[#030000]/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
                  <img
                    alt="Lottery prediction"
                    src="/images/lottery-angels-angel.png"
                    className="h-96 w-full flex-none rounded-2xl object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm"
                  />
                  <div className="w-full flex-auto">
                    <h2 className="text-3xl font-bold tracking-tight text-[#030000] sm:text-4xl">
                      Lottery Angels Tip Sheet
                    </h2>
                    <p className="mt-6 text-lg leading-8 text-[#030000]">
                      Get the best lottery predictions and tips from the Lottery
                      Angels Tip Sheet.
                    </p>
                    <ul
                      role="list"
                      className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-[#030000] sm:grid-cols-2"
                    >
                      {benefits.map((benefit) => (
                        <li key={benefit} className="flex gap-x-3">
                          <CheckCircleIcon
                            className="h-7 w-5 flex-none text-[#DB3E00]"
                            aria-hidden="true"
                          />
                          {benefit}
                        </li>
                      ))}
                    </ul>
                    <div className="mt-10 flex">
                      <a
                        href="https://lotteryangels.gumroad.com/l/tipsheet"
                        className="inline-block px-4 py-2 bg-[#DB3E00] text-white font-semibold rounded-lg hover:bg-[#c23700] transition duration-300 ease-in-out"
                      >
                        Get Lottery Angels Tip Sheet
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                aria-hidden="true"
                className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
              >
                <div
                  style={{
                    clipPath:
                      "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
                  }}
                  className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#E8D8AE] to-[#DB3E00] opacity-25"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="card bg-[#f5e5c9] p-6 rounded-lg shadow-md hover:shadow-lg overflow-hidden">
              <img
                src="/images/daily-numbers-angel.png"
                alt="Daily Numbers"
                className="w-full h-48 object-cover object-top mb-4 rounded mx-auto"
              />
              <h2 className="text-2xl font-bold mb-2">Daily Numbers</h2>
              <p className="mb-4">Get your daily lucky numbers</p>
              <Link
                to="/daily-numbers"
                className="block text-center bg-[#DB3E00] text-white px-4 py-2 rounded hover:bg-[#c23700] transition duration-300 ease-in-out"
              >
                Select Your State
              </Link>
            </div>
            <div className="card bg-[#f5e5c9] p-6 rounded-lg shadow-md hover:shadow-lg overflow-hidden">
              <img
                src="/images/birthstone-numbers-angel.png"
                alt="Birthstone Numbers"
                className="w-full h-48 object-cover object-top mb-4 rounded mx-auto"
              />
              <h2 className="text-2xl font-bold mb-2">Birthstone Numbers</h2>
              <p className="mb-4">Discover numbers based on your birthstone</p>
              <Link
                to="/birthstone-numbers"
                className="block text-center bg-[#DB3E00] text-white px-4 py-2 rounded hover:bg-[#c23700] transition duration-300 ease-in-out"
              >
                Select Your State
              </Link>
            </div>
            <div className="card bg-[#f5e5c9] p-6 rounded-lg shadow-md hover:shadow-lg overflow-hidden">
              <img
                src="/images/lucky-numbers-angel.png"
                alt="Lucky Numbers"
                className="w-full h-48 object-cover object-top mb-4 rounded mx-auto"
              />
              <h2 className="text-2xl font-bold mb-2">Lucky Numbers</h2>
              <p className="mb-4">Generate your personal lucky numbers</p>
              <Link
                to="/lucky-numbers"
                className="block text-center bg-[#DB3E00] text-white px-4 py-2 rounded hover:bg-[#c23700] transition duration-300 ease-in-out"
              >
                Select Your State
              </Link>
            </div>
          </div>

          <EmailSignup />
        </div>
      </Layout>
    </>
  );
}

export default HomePage;
