import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Birthstones() {
  const birthstones = [
    { name: "Garnet", month: "January" },
    { name: "Amethyst", month: "February" },
    { name: "Aquamarine", month: "March" },
    { name: "Diamond", month: "April" },
    { name: "Emerald", month: "May" },
    { name: "Pearl", month: "June" },
    { name: "Ruby", month: "July" },
    { name: "Peridot", month: "August" },
    { name: "Sapphire", month: "September" },
    { name: "Opal", month: "October" },
    { name: "Topaz", month: "November" },
    { name: "Turquoise", month: "December" },
  ];

  return (
    <>
      <Helmet>
        <title>Birthstone Lottery Numbers - Choose Your Birthstone</title>
        <meta
          name="description"
          content="Select your birthstone to see daily lottery number predictions based on your birth month's gemstone. Find lucky numbers for Pick 3 and Pick 4 games."
        />
      </Helmet>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 mb-8">
        {birthstones.map((birthstone) => (
          <Link
            key={birthstone.name}
            to={`/birthstone-numbers/${birthstone.name.toLowerCase()}`}
            className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow"
          >
            <img
              src={`/birthstone-images/${birthstone.name.toLowerCase()}.png`}
              alt={`${birthstone.name} birthstone`}
              className="w-full h-32 object-cover mb-2 rounded"
            />
            <p className="text-center font-semibold">{birthstone.name}</p>
            <p className="text-center text-sm text-gray-600">
              {birthstone.month}
            </p>
          </Link>
        ))}
      </div>
    </>
  );
}

export default Birthstones;
