import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import EmailSignup from "../components/EmailSignup";
import { Helmet } from "react-helmet";

function LuckyNumbers() {
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    birthMonth: "",
    birthYear: "",
    mathCheck: "",
  });
  const [luckyNumbers, setLuckyNumbers] = useState(null);
  const [mathProblem, setMathProblem] = useState({ num1: 0, num2: 0 });
  const [showForm, setShowForm] = useState(true);

  useEffect(() => {
    generateMathProblem();
  }, []);

  const generateMathProblem = () => {
    const num1 = Math.floor(Math.random() * 9) + 1;
    const num2 = Math.floor(Math.random() * 9) + 1;
    setMathProblem({ num1, num2 });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const correctAnswer = mathProblem.num1 + mathProblem.num2;
    if (parseInt(formData.mathCheck) !== correctAnswer) {
      alert("Incorrect math answer. Please try again.");
      return;
    }

    // Generate lucky numbers
    const pick3 = Math.floor(Math.random() * 1000)
      .toString()
      .padStart(3, "0");
    const pick4 = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, "0");
    setLuckyNumbers({ pick3, pick4 });

    // Submit email to subscribe endpoint
    try {
      const response = await fetch("https://api.lotteryangels.com/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: formData.email }),
      });

      if (!response.ok) {
        throw new Error("Failed to subscribe");
      }

      // If successful, hide the form
      setShowForm(false);
    } catch (error) {
      console.error("Error subscribing:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Generate Your Lucky Numbers - Lottery Angels</title>
        <meta
          name="description"
          content="Generate your lucky numbers for the lottery."
        />
      </Helmet>
      <Layout>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-bold mb-6">
            Generate Your Lucky Numbers
          </h1>
          {showForm ? (
            <form
              onSubmit={handleSubmit}
              className="bg-white p-6 rounded-lg shadow-md mb-8"
            >
              <div className="mb-4">
                <label htmlFor="firstName" className="block mb-2">
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block mb-2">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="birthMonth" className="block mb-2">
                  Birth Month
                </label>
                <select
                  id="birthMonth"
                  name="birthMonth"
                  value={formData.birthMonth}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border rounded"
                >
                  <option value="">Select a month</option>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="birthYear" className="block mb-2">
                  Birth Year
                </label>
                <input
                  type="number"
                  id="birthYear"
                  name="birthYear"
                  value={formData.birthYear}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="mathCheck" className="block mb-2">
                  Math Check: {mathProblem.num1} + {mathProblem.num2} =
                </label>
                <input
                  type="number"
                  id="mathCheck"
                  name="mathCheck"
                  value={formData.mathCheck}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border rounded"
                />
              </div>
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Generate Lucky Numbers
              </button>
            </form>
          ) : (
            <div className="bg-white p-6 rounded-lg shadow-md mb-8">
              <h2 className="text-2xl font-bold mb-4">
                Your Lucky Numbers for Today
              </h2>
              <p className="mb-2">
                Pick 3: <span className="font-bold">{luckyNumbers.pick3}</span>
              </p>
              <p>
                Pick 4: <span className="font-bold">{luckyNumbers.pick4}</span>
              </p>
            </div>
          )}

          <EmailSignup />
        </div>
      </Layout>
    </>
  );
}

export default LuckyNumbers;
