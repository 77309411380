import React from "react";
import { Link } from "react-router-dom";
import usStates from "../data/usStates";

function States() {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
      {usStates.map((state) => (
        <Link
          key={state.abbreviation}
          to={`/daily-numbers/${state.name.toLowerCase().replace(/\s+/g, "-")}`}
          className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow"
        >
          <img
            src={`/images/state-outlines/${state.name.replace(
              /\s+/g,
              "-"
            )}.svg`}
            alt={`${state.name} outline`}
            className="w-full h-32 object-contain mb-2"
          />
          <p className="text-center font-semibold">{state.name}</p>
        </Link>
      ))}
    </div>
  );
}

export default States;
