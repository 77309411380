import React, { useState } from "react";
import { Link } from "react-router-dom";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="bg-[#F5E5C9] p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-[#030000] text-2xl font-bold">
          Lottery Angels
        </Link>

        {/* Hamburger menu button */}
        <button
          className="md:hidden text-[#030000]"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>

        {/* Desktop menu */}
        <div className="hidden md:flex space-x-4">
          <Link
            to="/daily-numbers"
            className="text-[#030000] hover:text-[#DB3E00] transition duration-300 ease-in-out"
          >
            Daily Numbers
          </Link>
          <Link
            to="/birthstone-numbers"
            className="text-[#030000] hover:text-[#DB3E00] transition duration-300 ease-in-out"
          >
            Birthstone Numbers
          </Link>
          <Link
            to="/lucky-numbers"
            className="text-[#030000] hover:text-[#DB3E00] transition duration-300 ease-in-out"
          >
            Lucky Numbers
          </Link>
        </div>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden mt-4">
          <Link
            to="/daily-numbers"
            className="block text-[#030000] hover:text-[#DB3E00] py-2 transition duration-300 ease-in-out"
          >
            Daily Numbers
          </Link>
          <Link
            to="/birthstone-numbers"
            className="block text-[#030000] hover:text-[#DB3E00] py-2 transition duration-300 ease-in-out"
          >
            Birthstone Numbers
          </Link>
          <Link
            to="/lucky-numbers"
            className="block text-[#030000] hover:text-[#DB3E00] py-2 transition duration-300 ease-in-out"
          >
            Lucky Numbers
          </Link>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
